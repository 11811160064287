var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c(
        "el-button",
        {
          staticClass: "back-btn",
          attrs: { icon: "arrow-left" },
          on: { click: _vm.back }
        },
        [_vm._v("\n    返回\n  ")]
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
              _vm._v("\n        Oops!\n      ")
            ]),
            _vm._v("\n      gif来源"),
            _c(
              "a",
              { attrs: { href: "https://zh.airbnb.com/", target: "_blank" } },
              [_vm._v("airbnb")]
            ),
            _vm._v(" 页面\n      "),
            _c("h2", [_vm._v("你没有权限去该页面")]),
            _c("h6", [_vm._v("如有不满请联系你领导")]),
            _c("ul", { staticClass: "list-unstyled" }, [
              _c("li", [_vm._v("或者你可以去:")]),
              _c(
                "li",
                { staticClass: "link-type" },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _vm._v("\n            回首页\n          ")
                  ])
                ],
                1
              ),
              _c("li", { staticClass: "link-type" }, [
                _c("a", { attrs: { href: "https://github.com/armour" } }, [
                  _vm._v("随便看看")
                ])
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.dialogVisible = true
                      }
                    }
                  },
                  [_vm._v("点我看图")]
                )
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("img", {
              staticClass: "some-gif",
              attrs: {
                src: _vm.errGif,
                width: "313",
                height: "428",
                alt: "Girl has dropped her ice cream."
              }
            })
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: "随便看" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            staticClass: "some-img",
            attrs: { src: _vm.ewizardClap }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }